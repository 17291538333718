















































































import { buildUrlQueryList } from "@/builder";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import { Row, useBlob, useProduct } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import MQueryPage, { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE, PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    DisplayBoolean,
  },
})
export default class IndexPage extends Mixins(MNotificationVue, MQueryPage) {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  loading = {
    find: false,
    download: false,
  };

  dataSource: Row[] = [];
  columns = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "code",
      key: "code",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "name",
      key: "name",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "desc",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_category"),
      dataIndex: "categoryName",
      key: "category",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_type"),
      dataIndex: "type",
      key: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      key: "brand",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      scopedSlots: { customRender: "boolean" },
    },
    {
      title: this.$t("lbl_action"),
      scopedSlots: { customRender: "operation" },
    },
  ];

  mounted(): void {
    this.findProduct();
  }

  findProduct(): void {
    const { searchByRemastered } = useProduct();
    const params = new RequestQueryParams();
    params.search = searchByRemastered({
      categoryName: this.pagination.search,
      code: this.pagination.search,
      description: this.pagination.search,
      name: this.pagination.search,
      partNumber: this.pagination.search,
      type: this.pagination.search,
    });
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    this.fetchProduct(params);
  }

  fetchProduct(params: RequestQueryParams): void {
    const { findAllRemastered } = useProduct();
    this.loading.find = true;
    findAllRemastered(params)
      .then(res => {
        this.pagination.totalElements = res.totalElements;
        this.dataSource = res.data.map(item => ({
          ...item,
          key: item.id,
        }));
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  handleSearch(): void {
    this.resetCurrentPage();
    this.findProduct();
    this.updateQueryRoute();
  }

  onChangeTable(pagination, filters, sort): void {
    const { current, pageSize } = pagination;

    this.pagination.page =
      pageSize !== this.pagination.limit ? FIRST_PAGE : current;
    this.pagination.limit = pageSize;

    this.findProduct();
    this.updateQueryRoute();
  }

  updateQueryRoute(): void {
    this.$router.replace({
      name: this.$route.name || "",
      query: {
        ...this.$route.query,
        ...buildUrlQueryList({
          limit: this.pagination.limit,
          search: this.pagination.search,
          page: this.pagination.page,
        }),
      },
    });
  }

  handleDownload(): void {
    const { toDownload } = useBlob();
    const { download, buildDownloadParam, searchByRemastered } = useProduct();
    const params = new RequestQueryParams();
    params.params = buildDownloadParam();
    params.search = searchByRemastered({
      categoryName: this.pagination.search,
      code: this.pagination.search,
      description: this.pagination.search,
      name: this.pagination.search,
      partNumber: this.pagination.search,
      type: this.pagination.search,
    });
    params.limit = this.pagination.totalElements;

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "master_product_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  handleReset(): void {
    this.pagination.search = "";

    this.resetCurrentPage();
    this.findProduct();
    this.updateQueryRoute();
  }
}
